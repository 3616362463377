<template>
	<div class="pt-3 px-5 pb-2">
        <div class="mb-2 ResponsiveStartAudit">
            <breadCrumbs :routesArray="routesArray"></breadCrumbs>
	    </div>
		<div class="items-center justify-between flex ResponsiveStartAudit">
			<div class="title flex items-center">
				<h1>{{presentAudit.title}}</h1>
			</div>
			<div class="flex items-center">
                <div class="flex parent-toggle-border border rounded-sm border-lightgrey bg-white">
                <button :class="{ 'active-tab': activeButton === 'myControls', 'inactive-tab': activeButton !== 'myControls' }" class="flex items-center font-semibold justify-center rounded p-px leading-3 cursor-pointer w-28 h-8 text-xs" @click="showMyControls">
                    My Tasks
                </button>
                <button :class="{ 'active-tab': activeButton === 'allControls', 'inactive-tab': activeButton !== 'allControls' }" class="flex items-center font-semibold justify-center rounded p-px leading-3 cursor-pointer w-28 h-8 text-xs" @click="showAllControls">
                    All Tasks
                </button>
            </div>
            <button class="btnprimary ml-3 hidden items-center" @click="showAddControlPopup()">
                <img src="@/assets/plusIcon.svg" class="h-3 mr-2" />
                Add
            </button>
            <button class="filterButton ml-3 flex" @click="filterOpen" >
                Filters
                <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
            </button>
			</div>
		</div>
		<div class="mt-5 ResponsiveStartAudit">
			<p class="headerText">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>
        <!-- {{sortedData}} -->
        <div class="mt-5 ResponsiveStartAudit">
			<div class="tableCornerRadius overflow-x-auto bg-white"><div class="table-container">
				<div class="">
                  <table>
					<thead>
						<th scope="col" v-for="column in columnNames" :key="column" class="bg-white th1 cursor-pointer capitalize" :class="getColumnHeadWidth(column)">
							<div class="flex" :class="getPaddingForColumn(column)">
                                <div v-if="column == 'auditStatus'">
                                    <div>{{ 'Status' }}</div>
                                </div>
                                <div v-else-if="column == 'controlNumber'" >
                                    <div>{{ 'Control ' }}</div>
                                </div>
                                <div v-else-if="column == 'taskId'" >
                                    <div>{{ 'ID' }}</div>
                                </div>
                                <div v-else-if="column == 'controlName'">
                                    <div>{{ 'Title' }}</div>
                                </div>
                                 <div class="pl-5" v-else-if="isColumnFrameWork(column)">{{column}}</div>
								<div v-else>{{ column }}</div>
								<div @click="sort(column)" v-if="!isColumnFrameWork(column)" class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
							</div>
						</th>
						<tr v-if="showFilters">
							<td v-for="name in columnNames" class="bg-white" :key="name">
                                <div v-if="isColumnFrameWork(name)"></div>
                                <select v-else-if="name == 'auditStatus'" class="inputboxstylingForSelect ml-1 filterwidth" v-model="filterDummy[name]">
                                    <option :value="filterDummy.name" selected hidden disabled>Select</option>
                                    <option v-for="option in auditStatusCodes" :key="option.id" :value="option.id">{{ option.value }}</option>
                                </select>
                                <input v-else-if="name == 'controlNumber'" type="text" class="inputboxstylingForControlID" placeholder="Search" v-model="filterDummy[name]" />
								<input v-else type="text" class="searchForInputBoxStyling" placeholder="Search" v-model="filterDummy[name]" />
							</td>
						</tr>
					</thead>

                    <tbody class="empty" v-if="sortedData.length == 0">
                        <tr class="cursor-pointer rowHeight">
                            <td class="relative h-10" :colspan="columnNames.size">
                                <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                            </td>
                        </tr>
                    </tbody>

					<tbody class="nonEmptyTable" v-else>
                        
						<tr v-for="(row, index) in sortedData" :key="index" class="rowHeight" @click="goToControlTaskEdit(row._id)">
                            <td v-for="presentColumn in columnNames" :key="presentColumn" @mouseout="doFalse()" class="cursor-pointer relative">
                                <div class="h-full w-full flex items-center" v-if="isColumnFrameWork(presentColumn)">
                                    <div class="h-full w-full flex flex-wrap items-center" v-if="row[presentColumn].length>0" >
                                        <div v-for="ctrlNumber in row[presentColumn]" @click="clickedOnFrameWorkControlNumber(ctrlNumber,presentColumn)" :key="ctrlNumber" :class="getBorder()" class="text-xs m-2 px-2 py-0.5 font-opensans bg-white font-bold h-auto flex items-center" >
                                            {{ctrlNumber}}
                                        </div>
                                    </div>
                                    <div class="pl-4" v-else>
                                        {{'-'}}
                                    </div>
                                </div>
                                <!-- <div v-else-if="presentColumn == 'team'"><p class="cursor-pointer text-xs">{{ getTeamName(row[presentColumn]) }}</p></div> -->
							    <!-- <div v-else-if="presentColumn == 'entity'"><p class="cursor-pointer text-xs">{{ getLocationName(row[presentColumn]) }}</p></div> -->
                                <div v-else-if="presentColumn == 'auditStatus'">
                                    <div class="flex items-center justify-center rounded h-5 w-32 ml-1.5" :class="colorAuditBg(row.auditStatus)">
									<div :class="colorAuditText(row.auditStatus)">
										{{
											auditStatusCodes.find((l) => {
												return l.id == row[presentColumn];
											}).value
										}}
									</div>
								</div>
                                </div>
                                <div v-else>
                                    <div class="table-ellipsis">
                                        <div @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
                                            {{ row[presentColumn] }}
                                        </div>
                                    </div>
                                    <div v-if="pop == true && sortedData[tipIndex][presentColumn] == tipText && tipColumn == presentColumn && tipIndex == index">
                                        <tool-tip :columnName="presentColumn" :tipText="tipText" :styling="styling"></tool-tip>
                                    </div>
                                </div>
                            </td>
						</tr>
					</tbody>
				  </table>
                </div>
				<pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
			</div></div>
		</div>

        <popup v-if="addControlPopup" :popUp="addControlPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>New Control</h1>

                    <button class="float-right text-3xl" @click="onCloseAddPopup()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-business">
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-1/2 px-2">
                                        <label for="number" class="controllabel">Control Number</label><br />
                                        <input class="inputboxstyling" placeholder="Control Number" :value="controlAddObject.controlNumber" />
                                    </div>
                                    <div class="w-1/2 px-2">
                                        <label for="number" class="controllabel">Title</label><br />
                                        <input class="inputboxstyling" placeholder="Title" :value="controlAddObject.controlTitle" />
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-1/2 px-2">
                                        <label for="number" class="controllabel">Team</label><br />
                                        <select
                                            class="inputboxstyling"
                                            v-model="controlAddObject.team"
                                        >
                                            <option value='' selected disabled hidden>Please select one</option>

                                            <option v-for="option in teamObject" :key="option" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="w-1/2 px-2">
                                        <label for="number" class="controllabel">Location</label><br />
                                        <select
                                            class="inputboxstyling"
                                            v-model="controlAddObject.entity"
                                        >
                                            <option value='' selected disabled hidden>Please select one</option>

                                            <option v-for="option in locationObject" :key="option" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>

        <popup v-if="viewControlNumberPopup" :popUp="viewControlNumberPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>{{frameWorkControlNumberObject.selectedFrameWork}}</h1>

                    <button class="float-right text-3xl" @click="onCloseFrameWorkControl()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-business">
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full px-1">
                                        <label for="number" class="controllabel">Frame Work No:</label><br />
                                        <div class="inputboxstyling">{{frameWorkControlNumberObject.controlNumber}}</div>
                                        <!-- <input class="inputboxstyling" placeholder="Frame Work Number" :value="frameWorkControlNumberObject.controlNumber" /> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full px-1">
                                        <label for="number" class="controllabel">Title</label><br />
                                        <div class="inputboxstyling">{{frameWorkControlNumberObject.controlTitle}}</div>
                                        <!-- <input class="inputboxstyling" placeholder="Title" :value="frameWorkControlNumberObject.controlTitle" /> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full px-1">
                                        <label for="number" class="controllabel">Description</label><br />
                                        <!-- <textarea class="inputboxstyling" placeholder="Threat" v-model="frameWorkControlNumberObject.description"/> -->
                                        <div class="inputboxstyling">{{frameWorkControlNumberObject.description}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>
	</div>
</template>
<style scoped>
.popup-business {
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 0px;
}
.nonEmptyTable:last-child  {
	border-bottom: 1px solid #e9e9e9;
}
.rowHeight {
	height: 60px;
}
.numberBorder{
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}
.numberNavy{
    border-left: 1px solid #152A43;
    border-width: 2px;
}
.active-tab {
    background-color: #152a43;
    color: white;
}
.active-tab, .inactive-tab {
    transition: background-color 0.4s ease, color 0.4s ease;
}
.inactive-tab {
    color: black;
    fill: #152a43 !important;
}
.table-ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.pendingEvidence{
    background-color: #8600f040;
}
.evidenceUpload{
    background: rgba(21,39,60,0.24);
}
.filterwidth{
    width : 97%;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required } from '@vuelidate/validators';
import tableData from '@/components/tableData.vue';
import { mapGetters } from 'vuex';
import { emitter, toast } from '@/main';
import { groupBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import pagination from '@/components/pagination.vue';
import popup from '@/components/popup.vue';
import toolTip from '@/components/toolTip.vue';
import breadCrumbs from '@/components/breadCrumbs.vue';
import dotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
	data(): any {
		return {
			showFilters: false,
            visibility: false,
            allFrameWorkControls:[],
            filters: {},
            orgFrameWorks:[],
            presentAuditFrameWorks:[],
            tableRow:[],
            mainHeaders:[],
            filterDummy: {},
			classSort: '',
			className: '',
			dummyList:[],

			pageType: '',

			currentSort: 'name',
			currentSortDir: 'asc',

			currentPage: '',
			pageSize:'',
			dynamicPagination: '',
            teamObject:[],
            teamsData:[],
            locationObject:[],
            pop: false,
			styling: { left: '', bottom: '' },
			tipText: '',
			tipColumn: '',
			tipIndex: '',
            activeButton:'',
            addControlPopup:false,
            controlAddObject:{
                controlNumber:'',
                controlTitle:'',
                team:'',
                entity:'',
            },
            viewControlNumberPopup:false,
            frameWorkControlNumberObject:{},
            auditStatusCodes: [
				{
					id: 10401,
					value: 'Not Started'
				},
				{
					id: 10414,
					value: 'Pending Evidence'
				},
				{
					id: 10415,
					value: 'InReview'
				},
                {
					id: 10416,
					value: 'Evidence Uploaded'
				},
                {
					id: 10403,
					value: 'Completed'
				},
                {
					id: 10417,
					value: 'Not Applicable'
				},
                {
					id: 10418,
					value: 'Terminated'
				},

			],
            startAuditTitle:[],
            routesArray: [],
            presentAudit:{},
            internalControlsDetailsArray:[],
            mainAuditControls:[],
            tasksForAudit:[],
		};
	},
	components: {
        pagination,
        popup,
        toolTip,
        breadCrumbs,
        dotsLoader
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
        sortedData: function (): any {
			return [...this.tableRow]
				.filter((row: any, index: any) => {
					for (const key in this.filterDummy) {
						const inputValue = this.filterDummy[key];
						if (inputValue !== '' && row[key]) {
							if (Number.isInteger(row[key])) {
								if (row[key] !== Number(inputValue)) {
									return false;
								}
							} else {
								if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
									return false;
								}
							}
						}
					}
					return true;
				})
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination = index + 1;
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
        columnNames: function (): any {
			const names = new Set();
			for (const key of this.mainHeaders) {
				names.add(key);
			}
			return names;
		}
	},
	// async mounted() {
	// },
	methods: {
        getColumnname(column:any){
            column='Status'
            return column
        },
        colorAuditText(status: any) {
			if (status == 10401) {
				return ['font-semibold text-disabledgrey'];
			} else if (status == 10402 || status == 10415) {
				return ['font-semibold text-yellow'];
			} else if (status == 10403) {
				return ['font-semibold text-lightgreen'];
			} else if(status == 10414){
				return ['font-semibold mx-2 text-electricGreen']
			} else if(status == 10416){
				return ['font-semibold mx-2 text-primary']
			}else if(status == 10417 || status == 10418){
				return ['font-semibold mx-2 text-darkred']
			}
		},
		colorAuditBg(status: any) {
			if (status == 10401) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10402 || status == 10415) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else if (status == 10414) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10416) {
				return ['evidenceUpload border-solid border border-primary'];
			}else if (status == 10417 || status == 10418) {
				return ['bg-veryHighColor border-solid border border-darkred'];
			}
		},
        getColumnHeadWidth(column: any) {
            if (column == 'auditStatus') {
                return 'w-44';
            } else if( column == 'controlNumber' || column == 'taskId'){
                return 'w-28';
            }else if( column == 'team' || column == 'entity'){
                return 'w-40';
            }else {
                return '';
            }
        },
        getPaddingForColumn(column:any){
            if(column == 'auditStatus' || column == 'controlName'){
                return 'pl-7'
            }else{
                return ''
            }
        },
        isColumnFrameWork(presentColumn:any){
            if(this.presentAuditFrameWorks.includes(presentColumn)){
                return true
            }else{
                return false
            }
        },
        goToControlTaskEdit(taskId:any){
            this.$router.push({ name: 'controlEdit', params: { auditId: this.$route.params.auditId,taskId:taskId } });
        },
        clickedOnFrameWorkControlNumber(ctrlNum:any,frameWork:any){
            let findObj:any = this.allFrameWorkControls.find((obj:any)=>{return obj.controlNumber == ctrlNum && obj.controlFrameWork ==  frameWork})
            this.frameWorkControlNumberObject.selectedFrameWork = frameWork;
            this.frameWorkControlNumberObject.controlNumber = ctrlNum;
            if(findObj!= undefined){
                this.frameWorkControlNumberObject.controlTitle = findObj.controlTitle;
                this.frameWorkControlNumberObject.description = findObj.controlAuditMethodology
            }
            this.viewControlNumberPopup = true;
        },
        onCloseFrameWorkControl(){
            this.viewControlNumberPopup = false;
        },
        showAddControlPopup(){
            this.addControlPopup = true
        },
        onCloseAddPopup(){
            this.addControlPopup = false
            Object.keys(this.controlAddObject).forEach((prop) => {
				Array.isArray(this.controlAddObject[prop]) ? (this.controlAddObject[prop] = []) : (this.controlAddObject[prop] = '');				
			});
        },
        showMyControls(){
            this.activeButton = 'myControls'
        },
        showAllControls(){
            this.activeButton = 'allControls'
        },
        getBorder(){
            return `numberBorder numberNavy`
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
			this.tipText = row[column];
			this.tipColumn = column;
			this.tipIndex = index;

			switch (status) {
				case 'text':
					this.handleTextTooltip(e);
					break;
			}
		},
		handleTextTooltip(e: any) {
			const container = e.currentTarget.closest('.table-ellipsis');
			if (container && this.isTextOverflowed(container)) {
				this.pop = true;
				this.styling.left = e.offsetX + 38 + 'px';
				this.styling.bottom = -15 + 'px';
			} else {
				this.pop = false;
			}
		},
		isTextOverflowed(element: any) {
			return element.scrollHeight > element.clientHeight;
		},
		doFalse() {
			this.pop = false;
		},
        sort(tableHead: any) {
			this.className = tableHead;
			if (tableHead == this.currentSort) {
				this.currentSortDir = this.currentSortDir == 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = tableHead;
			this.classSort = this.currentSortDir == 'asc' ? 'sort_asc' : 'sort_desc';
		},
		paginationUpdations(currentPage: any, pageSize: any) {
			console.log('Pagination processing... ', currentPage, pageSize);
			this.currentPage = currentPage;
			this.pageSize = pageSize;
		},
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
            this.filterDummy = {}
		},
        getTeamName(ownerId: any) {
            let user = this.teamObject.find((obj: any) => {
                return obj.value == ownerId;
            });
            
            if (user == undefined) {
                let empty = '';
                return empty;
            } else {
                return user.label;
            }
        },
		 getLocationName(locationId: any) {
            let locationObj = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
            if (locationObj == undefined) {
                let empty = '';
                return empty;
            } else {
                return locationObj.label;
            }
        },
        async getAllControls(){
            await this.$http
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`)
				.then((result: { data: any }) => {
                    let controls:any = result.data;
                    this.allFrameWorkControls = result.data;
				})
				.catch((error: any) => {
                    toast.error(` ${error}`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
				});
        },
        async getAllTeams() {
            this.teamObject = []
            let users:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                users = res.data;
                this.teamsData = res.data;
            });
            for (var i = 0; i < users.length; i++) {
                this.teamObject.push({ label: users[i].teamName, value: users[i]._id });
            }
        },
		 async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
            console.log("locationObject",this.locationObject)
        },
        async getPresentAudit(){
			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audits/getAll`).then((result: any) => {
				// this.existingAuditsArray = [...result.data];
                this.presentAudit = result.data.find((obj:any)=>{
                   return  obj._id == this.$route.params.auditId;
                })
               this.presentAuditFrameWorks =  [...this.presentAudit.frameworks]
			})
		},
        async startSelectedAuditTasks(cameId:any){
            if(this.presentAudit.statusCode == 10401){
                try{
                    await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${cameId}/start`).then(async (result: any) => {
                        console.log('HI ABHIRAM STATUS CHaNED')
                    })
                }catch{
                    toast.error(`Start Failed`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            }
            if(!Object.keys(this.presentAudit).includes('tasks')){
                await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${cameId}/startAudit`).then(async (result: any) => {
                    this.internalControlsDetailsArray = [...result.data];

                    let workflowPayload:any = [];
                    this.internalControlsDetailsArray.map((obj:any,index:any)=>{
                        const control_ids = obj.controls.map((control:any) => control._id);
                        let object:any = {
                            'auditId':cameId,
                            'controls': control_ids,
                            'department': obj.department,
                            'createdAt': new Date(),
                            'createdBy': this.userInfo._id
                        }
                        workflowPayload.push(object);
                    })

                    //inititae the work flow api for task creation
                    if( !Object.keys(this.presentAudit).includes('tasks') && this.internalControlsDetailsArray.length > 0){
                        await this.createWorkFlowTasksForAudit(workflowPayload)
                    }
                })
            }
			
		},
        async createWorkFlowTasksForAudit(payload:any){
            try{
                 await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/api/org/trigger/workflow/${process.env.VUE_APP_WORKFLOW_ID}/audit/task`, payload).then((result: any) => {
                   console.log('task created')
                });
            }catch{
                toast.error(`Task Creation Failed`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async getTasksForAudit(id:any){
            this.tasksForAudit = []
            try{
                 await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${id}/tasks/get`).then((result: any) => {
                   this.tasksForAudit = result.data;
                });
            }catch{
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },

        async getDetailsForTable(){

            this.tableRow = []
            this.tasksForAudit.map((taskObj:any)=>{
                let presentLocation:any
                
                let presentteamObject:any = this.teamsData.find((obj: any) => {
                    return obj._id == taskObj.department;
                });

                if (presentteamObject.type == 20301){
                    presentLocation = 'Global';
                }else{
                    if(taskObj.location != undefined){
                        presentLocation = this.getLocationName(taskObj.location)
                    }else{
                        presentLocation = '-'
                    }
                }

                let sendObject:any = { 
                        "_id": taskObj._id, 
                        "taskId": taskObj.taskId ,
                        "CCID": taskObj.clientUniqueId,
                        "controlName": taskObj.controlTitle, 
                        "team": presentteamObject.teamName ,
                        "entity": presentLocation,
                        "auditStatus": taskObj.statusCode
                    };
                
                // console.log('ksadskudwsd',sendObject)

                // this.presentAuditFrameWorks.map((key:any)=>{
                //     sendObject[key] = [];
                //     console.log('jsbdjsdus',key)
                //     console.log('taskObj.frameworkstaskObj.frameworks',taskObj)
                //     let frameWorkObj = taskObj.frameworks.find((frame:any)=>{return frame.framework == key || frame.frameWork == key});
                //     console.log('frameWorkObjframeWorkObj',frameWorkObj)
                //     if(frameWorkObj != undefined){
                //         sendObject[key] = frameWorkObj.controls
                //     }
                // })

                this.tableRow.push(sendObject)
            })
            // this.internalControlsDetailsArray.map((teamIntControlsObject:any,mainIndex:any)=>{

            //     //entity for dept shoudl be find
            //     let presentteam:any = this.getTeamName(obj.department);
            //     let presentLocation:any = this.getLocationName(this.presentAudit.location[0])

            //     teamIntControlsObject['controls'].map((intControlObj:any,index:any)=>{

            //         let findTaskObject:any = this.tasksForAudit.find((obj:any)=>{return obj.controlNumber == intControlObj.controlNumber})
                    
            //         let sendObject:any = { 
            //             "_id":intControlObj._id , 
            //             "taskId": findTaskObject._id ,
            //             "controlNumber": intControlObj.controlNumber,
            //             "controlName": intControlObj.controlTitle, 
            //             "team": presentteam ,
            //             "entity": presentLocation ,
            //             "auditStatus": findTaskObject.statusCode
            //         };
                    
                    
            //         this.presentAuditFrameWorks.map((key:any)=>{
            //             sendObject[key] = [];
            //             console.log('jsbdjsdus',key)
            //             console.log('cameeemcaemeeeee',intControlObj)
            //             let frameWorkObj = intControlObj.frameworks.find((obj:any)=>{return obj.framework == key || obj.frameWork == key});
            //             console.log('frameWorkObjframeWorkObj',frameWorkObj)
            //             if(frameWorkObj != undefined){
            //                 sendObject[key] = frameWorkObj.controls
            //             }
            //         })

            //         this.tableRow.push(sendObject)
            //     })
            // })
        }
	},

	async created() {
        await this.getPresentAudit();
        // if(this.presentAudit.statusCode == 10401){
            await this.startSelectedAuditTasks(this.$route.params.auditId)
        // }
        this.showMyControls();
        await this.getAllTeams();
        await this.getallEntities();
        await this.getAllControls()
        
        // let headersData = ['controlNumber','controlName',...this.presentAuditFrameWorks,'team','entity','auditStatus']
        let headersData = ['taskId','CCID','controlName','team','entity','auditStatus']

        this.mainHeaders = headersData;

        await this.getTasksForAudit(this.presentAudit._id)

         console.log("this.presentAudit.title",this.presentAudit)
        this.routesArray = [
            { name: 'Audit', routeName: 'audit' },
             { name: this.presentAudit.title , routeName: 'view-audit' },
            { name: 'Tasks' , routeName: 'start-audit' },
            // { name: this.presentTaskObject.taskId, routeName: 'controlEdit' }
        ];
        //  this.routesArray = [
        //     { name: 'Audit' , routeName: 'audit' },
        //     { name: 'Task Status / Analytics', routeName: 'view-audit' },
        //     { name: this.presentAuditObject.title, routeName: 'start-audit' },
		// 	{ name: this.presentTaskObject.taskId, routeName: 'controlEdit' }
        // ];

        await this.getDetailsForTable();

        if(Object.keys(this.$route.query).includes('tasks')){
            let presentFilter:any = this.$route.query.tasks
            this.showFilters = true;
            this.filterDummy.auditStatus = presentFilter;
        }
    },
    
    async mounted(){
        emitter.on('visible', (isBoolean: any) => {
			this.visibility = isBoolean;
			this.visibility == false ? this.filters = {}:'';
		});
       
    }
});
</script>

<template>
	<div class="pt-3 px-5 pb-2">
        <div class="mb-2">
            <breadCrumbs :routesArray="routesArray"></breadCrumbs>
        </div>
		<div class="items-center justify-between flex ResponsiveStartAudit">
			<div class="title flex items-center">
				<h1>Audit Findings</h1>
			</div>
            <div>
                <button class="filterButton ml-3 flex" @click="filterOpen" >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
			</div>
		</div>
		<div class="mt-5 ResponsiveStartAudit">
			<p class="headerText">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>
        <div>
			<tableData :tableRowData="tableRow" :headersData="mainHeaders"></tableData>
		</div>
         <popup v-if="showPopUp" :popUp="showPopUp">
			<template v-slot:header>
				<div class="flex items-center justify-between m-4 rounded">
					<h1>Findings</h1>
					<button class="float-right text-3xl" @click="onCancelButtonAddPopUp()">
						<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
					</button>
				</div>
			</template>
			<fieldset>
				<div class="statuscontainer popupbodycontainer">
					<div class="formcontainer flex flex-col">
						<!-- <div class="row flex flex-row w-full upperspacing">
							<div class="w-full pr-2">
								<label class="controllabel">Finding-Id</label>
								<input type="text" disabled class="inputboxstyling"/>
							</div>
						</div> -->
						<div class="row flex flex-row w-full upperspacing">
							<div class="w-1/2 pr-2">
								<label class="controllabel">Framework</label>
                                <!-- <div class="inputboxstyling">{{actionObj.frameworks}}</div> -->
                                <input class="inputboxstyling" disabled v-model="ActionObj.frameworks"/>
							</div>
							<div class="w-1/2 pr-2">
								<label class="controllabel">Control Number</label>
                                <!-- <div class="inputboxstyling">{{actionObj.frameworks}}</div> -->
                                <input class="inputboxstyling" disabled v-model="ActionObj.frameWorkControl"/>
							</div>
						</div>
                        <div class="row flex flex-row w-full upperspacing">
							<div class="w-full">
								<label class="controllabel">Finding Category</label>
								  <!-- <input class="inputboxstyling" v-model="ActionObj.findingCategory"/> -->
                                  <select class="inputboxstyling cursor-pointer" disabled v-model="ActionObj.findingCategory">
									<option value="" selected disabled hidden>Please select one</option>
									<option v-for="i in findingCategoryData" :value="i.id" :key="i.id">
										{{ i.value }}
									</option>
								</select>
							</div>
						</div>
						<div class="w-full upperspacing">
							<label class="controllabel">Findings</label>
							<textarea class="inputboxstyling resize-none" disabled v-model="ActionObj.findingComment" placeholder="Findings" rows="3" ></textarea>
						</div>
						<div class="w-full upperspacing">
							<label class="controllabel">Finding related to Evidence</label>
							<textarea class="inputboxstyling resize-none" disabled v-model="ActionObj.findingEvidence" placeholder="Finding related to Evidence" ></textarea>
						</div>
						<div class="w-1/2 upperspacing">
							<label class="controllabel">Control Audit Result</label>
							<input class="inputboxstyling cursor-pointer" disabled v-model="ActionObj.auditResult" type="text" placeholder="Default will be Team Owner" />
						</div>
					</div>
				</div>
			</fieldset>
		</popup>

	</div>
</template>
<style scoped>
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { emitter, toast } from '@/main';
import tableData from '@/components/tableData.vue';
import popup from '@/components/popup.vue';
import breadCrumbs from '@/components/breadCrumbs.vue';

export default defineComponent({
	data(): any {
		return {
			showFilters: false,
            visibility: false,
            filters: {},
            orgFrameWorks:[],
            presentAuditFrameWorks:[],
            tableRow:[],
            mainHeaders:[],
			dummyList:[],
            teamObject:[],
            locationObject:[],
            activeButton:'',
            addControlPopup:false,
            myTasksArray:[],
            tasksForAudit:[],
            teamsData:[],
            showPopUp : false,
            routesArray:[],
            ActionObj : {
				frameworks: '',
                frameWorkControl:'',
				findingCategory: '',
				findingComment: '',
				findingEvidence: '',
				auditResult: ''
            },
            findingCategoryData: [
				{
					id: 1,
					value: 'Minor'
				},
				{
					id: 2,
					value: 'Major'
				},
				{
					id: 3,
					value: 'Oppurtunity For Improvement'
				},
				{
					id: 4,
					value: 'Observation'
				}
			],

		};
	},
	components: {
        tableData,
        popup,
        breadCrumbs
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
	},
	methods: {
        filterOpen() {
			this.showFilters = !this.showFilters;
			if (this.showFilters == true) {
                this.dummyList = this.tableRow;
            } else {
                this.tableRow = this.dummyList;
            }
			emitter.emit('visible', this.showFilters);
		},
        async getAllTeams() {
            this.teamObject = []
            let users:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                users = res.data;
                this.teamsData = res.data;
            });
            for (var i = 0; i < users.length; i++) {
                this.teamObject.push({ label: users[i].teamName, value: users[i]._id });
            }
        },
		 async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
            console.log("locationObject",this.locationObject)
        },
        async getTasksForAudit(id:any){
            this.tasksForAudit = []
            try{
                 await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${id}/tasks/get`).then((result: any) => {
                   this.tasksForAudit = result.data;
                });
            }catch{
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        getLocationName(locationId: any) {
            let user = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
            if (user == undefined) {
                let empty = '-';
                return empty;
            } else {
                return user.label;
            }
        },

        async getDetailsForTable(){

            this.tableRow = [];
            this.tasksForAudit.map((taskObj:any)=>{
                if(taskObj.findings !== undefined){

                    let presentLocation:any
                
                    let presentteamObject:any = this.teamsData.find((obj: any) => {
                        return obj._id == taskObj.department;
                    });

                    if (presentteamObject.type == 20301){
                        presentLocation = 'Global';
                    }else{
                        if(taskObj.location == undefined || taskObj.location==null){
                            presentLocation = '-'
                        }else{
                            presentLocation = this.getLocationName(taskObj.location[0].entity)
                        }
                    }

                    taskObj.findings.map((obj:any)=>{
                        obj.entityName = presentLocation;
                        obj.teamName = presentteamObject.teamName;
                        obj.controlNumber = taskObj.controlNumber;
                        this.tableRow.push(obj)
                    }) 
                }
            })
        },
        onCancelButtonAddPopUp() {
			this.showPopUp = false;
		},

	},

	async created() {
        emitter.off('clickedOnDropDownAction')
        await this.getAllTeams();
        await this.getallEntities();
        
        let headersData = ['findingId','findingComment','controlNumber','teamName','entityName','frameworks','findingCategory']

        this.mainHeaders = headersData;

        await this.getTasksForAudit(this.$route.params.auditId)

        await this.getDetailsForTable();
        if(Object.keys(this.$route.query).includes('view')){
        this.routesArray = [
            { name: 'Audit' , routeName: 'audit' },
            { name: 'Task Status / Analytics', routeName: 'view-audit' },
            { name: 'Findings', routeName: 'auditFindings' }
        ];
        }else{
            this.routesArray = [
            { name: 'Audit' , routeName: 'audit' },
            { name: 'Findings', routeName: 'auditFindings' }
        ];
        }
    },
    
    async mounted(){
        emitter.on('visible', (isBoolean: any) => {
			this.visibility = isBoolean;
			this.visibility == false ? this.filters = {}:'';
		});

        emitter.off('clickedOnDropDownAction')
		emitter.on('clickedOnDropDownAction',async (actionObj:any)=>{
            this.ActionObj.frameworks = actionObj.frameworks;
            this.ActionObj.findingCategory = actionObj.findingCategory;
            this.ActionObj.findingComment = actionObj.findingComment;
            this.ActionObj.findingEvidence = actionObj.findingEvidence;
            this.ActionObj.auditResult = actionObj.auditResult;
            if(actionObj.frameWorkControl != undefined){
                this.ActionObj.frameWorkControl = actionObj.frameWorkControl
            }else{
                this.ActionObj.frameWorkControl = ''
            }
            this.showPopUp = true;
		})
       
    }
});
</script>

<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumbs :routesArray="routesArray"></breadCrumbs>
        </div>
        <div class="items-center justify-between flex ResponsivestartPresentAudit">
			<div class="title flex items-center">
				<h1>{{presentAuditObject.title}}</h1>
			</div>
            <div class="flex">
                 <button class="filterButton mr-3 flex h-8" v-if="selectedTabIndex == 3 "  @click="filterOpenForAuditInfo" >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
                     <button  class="mr-3 flex h-8" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none mr-3 flex h-8' : 'filterButton mr-3 h-8'" v-if="selectedTabIndex == 4"  @click="filterOpenForFindings" >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
                <button @click="startPresentAudit()" >
                    <div class="btnprimary items-center btnPrimaryPadding" v-if="presentAuditObject.statusCode == 10401">Start</div>
                    <div class="btnPrimaryPadding btnprimary items-center" v-else>Tasks</div>
                </button>
                <button @click="onclickTerminate()" v-if="presentAuditObject.statusCode != 10418 && tasksOpen && presentAuditObject.statusCode != 10401" :class="tasksOpen == true ? 'btnprimary':'btndisabled'" class="ml-3 items-center">
                    <div>Terminate</div>
                </button>
                <!-- <button class="btnprimary mr-3 items-center" @click="closeAudit()" :class="tasksOpen == false ? 'btnprimary':'btndisabled'">
                    Close
                </button> -->
            </div>
		</div>
        <!-- {{ locationObject }} -->
        <div class="mainContainer tabStructure  w-full bg-white border rounded-sm mt-5 px-5 pt-5 pb-3 border-lightgrey">
            <div>
                    <div class="tabStructureDiv">
                        <div class="flex flex-row w-1/2 borderBottom justify-between cursor-pointer tab-container">
                            <div class="pb-1 " :class="selectedTabIndex == tab.id ? 'adjust header font-semibold':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in tabHead" :key="tabIndex" @click="toggleTabs(tab)">
                                <div :class="{ 'anime-left': !sideButton, 'anime-right': sideButton }">
                                    <div class="cursor-pointer">{{ tab.displayName }}</div>
                                    <div :class="{ progress: selectedTabIndex == tab.id }" class="h-1 p-0.75"></div>
                                </div>
                            </div>
                        </div>
                        <div class="progressbarB rounded h-1"></div>
                    </div>
                    <div class="h-full">
                        <div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 1">
                            <div class="lg:flex">
                            <div class="lg:w-1/2">
                                <div v-if="series.length > 0" class="w-full h-full flex justify-center items-center">
                                        <div id="chart">
                                                <apexchart type="pie" width="380" :options="chartOptions" @dataPointSelection="clickOnPieLabel" :series="series"></apexchart>
                                        </div>
                                </div>
                            </div>
                            <div class="lg:w-1/2 topPaddingForTable ">
                                <!-- <p class="text-base font-semibold text-center mt-2">jsdjksdh</p>  -->
                                    <div class="tableCornerRadius w-90">
                                        <table>
                                            <thead>
                                                <th scope="col" v-for="column in headers" :key="column" :class="getColumnHeadWidth(column)" class="bg-white th1 cursor-pointer capitalize">
                                                    <div v-if="column == 'taskStatus'">
                                                        <div>{{''}}</div>
                                                    </div>
                                                    <div v-else class="flex">
                                                    
                                                        <div >{{ column }}</div>
                                                        <!-- <div @click="sort(column)" v-if="!isColumnFrameWork(column)" class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>  -->
                                                    </div>
                                                </th>
                                                
                                            </thead>
                                            <tbody>
                                                <tr v-for="(row, index) in viewAuditObj" :key="index" class="rowHeight">
                                                    <td v-for="presentColumn in headers" :key="presentColumn" class="cursor-pointer relative">
                                                        
                                                        <div v-if="presentColumn == 'status'">
                                                            <div :class="textColor(row['taskStatus'])">
                                                                {{
                                                                    taskStatusCodes.find((l)=>{
                                                                        return  l.id == row['taskStatus']
                                                                    }).value
                                                                }}
                                                            </div>
                                                            <!-- {{row['taskStatus']}}  -->
                                                        </div>
                                                        <div v-else>
                                                            <div class="table-ellipsis">
                                                                <div :class="presentColumn == 'tasks'?'pl-3':''">
                                                                    {{ row[presentColumn] }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-xs flex items-center justify-center w-90 pt-2 mb-4"><p class="font-semibold text-darkred">*Note : </p> To close the Audit All Tasks should be in completed state.</div>
                                </div>
                            </div>
                            
                    </div>
                        <div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 2">
                                <div class="">
                                         <div class="row flex flex-row w-full ">
                                            <div class="w-1/2 pr-2">
                                                <label class="controllabel ">Audit Period</label>
                                                 <div class="row flex flex-row w-full mt-2 text-sm">{{changeDateFormat(presentAuditObject.period.fromDate)}}
                                                <div class="pl-1 pr-1 text-sm">-</div>
                                                {{changeDateFormat(presentAuditObject.period.toDate)}}
                                            </div>
                                            </div>
                                        </div>
                                        <div class="row flex flex-row w-full mt-3">
                                            <div class="w-1/2 pr-2">
                                                <label class="controllabel ">Audit Start Date</label>
                                                <div class="text-sm mt-2">{{changeDateFormat(presentAuditObject.startDate)}}</div>
                                            </div>
                                            <div class="w-1/2 pr-2">
                                                <label class="controllabel ">Audit End Date</label>
                                                <div class="text-sm mt-2">{{changeDateFormat(presentAuditObject.endDate)}}</div>
                                            </div>
                                        </div>
                                         <div class="row flex flex-row w-full mt-3">
                                            <div class="w-1/2 pr-2">
                                                <label class="controllabel ">Audit Title</label>
                                                <div class="text-sm mt-2">{{presentAuditObject.title}}</div>
                                            </div>
                                            <div class="w-1/2 pr-2">
                                                <label class="controllabel ">Audit Id</label>
                                                <div class="text-sm mt-2">{{presentAuditObject.auditId}}</div>
                                            </div>
                                        </div>
                                        <div class="row flex flex-row w-full mt-3">
                                            <div class="w-1/2 pr-2">
                                                <label class="controllabel ">Team Leader</label>
                                                <div class="text-sm mt-2">{{presentAuditObject.teamLeader.label}}</div>
                                            </div>
                                            <div class="w-1/2 pr-2">
                                                <label class="controllabel ">Frameworks (Maximum 3 Frameworks )</label>
                                                <div class="text-sm flex flex-wrap">
                                                    <div v-for="framework in presentAuditObject.frameworks" :key="framework" class="pt-2">
                                                       <div class="w-auto h-6 bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm">  {{framework}}  </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row flex flex-row w-full mb-5 mt-3">
                                            <div class="w-1/2 pr-2">
                                                <label class="controllabel ">Location</label>
                                                <div class="text-sm flex flex-wrap">
                                                    <div v-for="location in presentAuditObject.location" :key="location" class="pt-2">
                                                       <div class="w-auto h-6 bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm ">  {{location}} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-1/2 pr-2">
                                                <label class="controllabel ">Audit Members</label>
                                                <div class="text-sm flex mt-2 flex-wrap">
                                                    <div v-for="member in presentAuditObject.auditMembers" :key="member" class="pb-2">
                                                       <div class="w-auto h-6 bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm "> {{member}} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                               </div>
                            </div>
                        <div class="overflow-y-scroll descriptionToggle mt-3" v-if="selectedTabObj.id == 3">
                            <!-- <div class="ml-4 pt-4 bg-white rounded border border-lightgrey p-3"> -->
                                    <div>
                                        <tableData :tableRowData="teamsArray" :headersData="Headers"></tableData>
                                    </div>
                            <!-- </div> -->
                        </div>
                        <div>
                            <div class="overflow-y-scroll descriptionToggle mt-3" v-if="selectedTabObj.id == 4">
                                <div @click="findingsTabRowData()">
                                    <!-- @click="findingsTabRowData()"  -->
                                    <tableData :tableRowData="tableRow" :headersData="mainHeaders"></tableData>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        <popup v-if="showPopUp && selectedTabObj.id == 4" :popUp="showPopUp">
            <template v-slot:header>
                <div class="flex items-center justify-between m-4 rounded">
                    <h1>Finding</h1>
                    <button class="float-right text-3xl" @click="onCancelButtonAddPopUp()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div class="statuscontainer popupbodycontainer">
                    <div class="formcontainer flex flex-col">
                        <div class="row flex flex-row w-full mt-3">
							<div class="w-1/2 pr-2">
								<label class="controllabel ">Finding-Id</label>
                                 <div class="text-sm mt-2">{{ActionObj.findingId}}</div>
							</div>
							<div class="w-1/2 pr-2">
								<label class="controllabel ">Finding Category</label>
                                <div class="text-sm mt-2">{{
                                    findingCategoryData.find((category)=>{
                                        return category.id == ActionObj.findingCategory
                                    }).value
                                    }}</div>
                                 <!-- <select class="text-sm mt-2 custom-select" disabled v-model="ActionObj.findingCategory">
									<option value="" selected disabled hidden>Please select one</option>
									<option v-for="i in findingCategoryData" :value="i.id" :key="i.id">
										{{ i.value }}
									</option>
								</select> -->
							</div>
						</div>
                        <div class="row flex flex-row w-full mt-3">
							<div class="w-1/2 pr-2">
								<label class="controllabel ">Framework</label>
                                 <div class="text-sm mt-2">{{ActionObj.frameworks}}</div>
							</div>
							<div class="w-1/2 pr-2">
								<label class="controllabel ">Control Number</label>
                                 <div :class="getBorder()" class="text-xs mt-2 px-2 py-0.5 font-opensans bg-white font-bold h-auto w-fit flex items-center">{{ActionObj.frameWorkControl}}</div>
							</div>
						</div>
                        <div class="w-full mt-3">
							<label class="controllabel ">Findings</label>
                            <div class="text-sm mt-2">{{ActionObj.findingComment}}</div>
						</div>
						<div class="w-full mt-3">
							<label class="controllabel ">Evidence</label>
                            <div class="text-sm mt-2">{{ActionObj.findingEvidence}}</div>
						</div>
						<div class="w-1/2 mt-3">
							<label class="controllabel ">Control Audit Result</label>
                            <!-- <div class="text-sm mt-2">{{ActionObj.auditResult}}</div> -->
                             <div class="mt-2">
                                <div v-if="ActionObj.auditResult === 'Pass'" class="flex items-center justify-center rounded h-5 w-28 text-lightgreen bg-veryLowColor border-solid border border-lightgreen text-xs">{{ActionObj.auditResult}}</div>
                                <div v-if="ActionObj.auditResult === 'Fail'" class="flex items-center justify-center rounded h-5 w-28 text-darkred bg-veryHighColor border-solid border border-darkred ">{{ActionObj.auditResult}}</div>
                            </div>
						</div>
                    </div>
                </div>
            </fieldset>
        </popup>

        <popup v-if="showTerminatePopup" :popUp="showTerminatePopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Terminate - {{presentAuditObject.title}}</h1>
                    <button class="float-right text-3xl" @click="onCancelTerminate()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-business">
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full px-1">
                                        <label for="number" class="controllabel">Reason/Justification for Terminating the Audit</label><br />
                                        <textarea class="inputboxstyling resize-none" v-model="terminateObj.terminateComments" placeholder="Reason/Justification for Terminating the Audit" rows="3" ></textarea>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full px-1 flex">
                                        <input class="checkbox mr-2" type="checkbox" id="retainFindings" v-model="terminateObj.retainFindings"/>
                                        <label for="retainFindings" class="controllabel">Retain Findings</label>
                                    </div>
                                </div>
                            </div>
                            <div class="formcontainer flex justify-center mt-2">
                                <button @click="TerminatePresentAudit()" :class="terminateObj.terminateComments =='' ? 'btndisabled pointer-events-none': 'btnprimary'">Terminate</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>
    </div>
</template>


<style scoped>
th{
    height: 40px !important;
}
.rowHeight {
    height: 20px !important;
}
.tableCornerRadius{
    margin: 0 ;
}
.multiselect__select {
    display: none !important;
}
.topPaddingForTable{
    padding-top : 22px;
}
.progress {
	background: #152a43;
	position: relative;
	border-radius: 4px;
	z-index: 2;
}
.progressbarB {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	z-index: 1;
}
.descriptionToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.descriptionToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.anime-left {
    animation-name: moveLeft;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
.anime-right {
    animation-name: moveRight;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
.numberBorder{
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}
.numberNavy{
    border-left: 1px solid #152A43;
    border-width: 2px;
}
.btnPrimaryPadding{
    padding-left : 16px;
	padding-right : 16px;
}
.custom-select {
    background-image: url("../assets/Collapse.svg") !important;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: 12px 12px;
    background-position: right 15px center; /* Change the px value here to change the distance */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
</style>

<script lang="ts">

import { toast } from '@/main';
import breadCrumbs from '@/components/breadCrumbs.vue';
import Vue, { defineComponent } from 'vue';
import tableData from '@/components/tableData.vue';
import Multiselect from 'vue-multiselect';
import { emitter } from '@/main';
import popup from '@/components/popup.vue';
import { mapGetters } from 'vuex';
import dotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
    data(): any{
        return{
            headers: ['status','tasks'],
            Headers: ['teamName1','entityName1','auditee','auditor'],
            series: [],
            chartOptions: {
                chart: {
                    type: 'pie',
                },
                colors: ['#a4a4a4', '#FF9900', '#8600F0', '#152a43', '#00B050','#C00000','#C00000'],
                labels: ['Not Started', 'In Review', 'Pending Evidence', 'Evidence Uploaded', 'Completed','Not Applicable','Terminated'],
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false,
                    position: 'bottom',
                    horizontalAlign: 'left',
                    markers: {
                        width: 9, height: 9
                    },
                    itemMargin: {
                        horizontal:10, vertical: 0
                    },
                },
            },
            auditorAndAuditeeInfo:[],
            viewAuditObj: [],
            routesArray:[],
            // navIndexNums: { name: '', index: '' },
            showFiltersForFindings : false,
            showFiltersForAuditInfo : false,
            taskStatusCodes: [
				{
					id: 10401,
					value: 'Not Started'
				},
                {
					id: 10415,
					value: 'In Review'
				},
				{
					id: 10414,
					value: 'Pending Evidence'
				},
                {
					id: 10416,
					value: 'Evidence Uploaded'
				},
                {
					id: 10403,
					value: 'Completed'
				},
                {
					id: 10417,
					value: 'Not Applicable'
				},
                {
					id: 10418,
					value: 'Terminated'
				},
			],
            frameworks:[],
            presentAuditObject:{},
            tasksOpen:true,
            tasksForAudit:[],
            startDate:'',
            endDate:'',
            auditPeriod:'',
            teamsArray:[],
            optionsUserArray:[],
            orgUsersList:[],
            selectedTabObj:{},
            selectedTabIndex: 1,
            tabHead: [
				{ displayName: 'Task Analytics', name: 'Task Analytics' , id: 1 },

				{ displayName: 'Properties', name: 'Properties', id: 2 },

				{ displayName: 'Audit Info', name: 'Audit Info', id: 3 },

				{ displayName: 'Findings', name: 'Findings', id: 4 },
			],
             sideButton: false,
              teamObject:[],
              teamsData:[],
            locationObject:[],
            tableRow : [],
             ActionObj : {
				frameworks: '',
				findingCategory: '',
				findingComment: '',
				findingEvidence: '',
				auditResult: '',
                findingId : ''
            },
             showPopUp : false,
             findingCategoryData: [
				{
					id: 1,
					value: 'Minor'
				},
				{
					id: 2,
					value: 'Major'
				},
				{
					id: 3,
					value: 'Oppurtunity For Improvement'
				},
				{
					id: 4,
					value: 'Observation'
				}
			],
            showTerminatePopup:false,
            terminateObj:{
                terminateComments:'',
                retainFindings:false
            },
            optionsLocationArray : [],
            findingsForAudit:[]
        }
    },
    components:{
        breadCrumbs,
        tableData,
        Multiselect,
        popup,
        dotsLoader
    },
    computed:{
        ...mapGetters({ userInfo: 'userInfo' }),
    },
    methods:{
        getBorder(){
            return `numberBorder numberNavy`
        },
          filterOpenForFindings() {
			this.showFiltersForFindings = !this.showFiltersForFindings;
			if (this.showFiltersForFindings == true) {
                this.dummyList = this.tableRow;
            } else {
                this.tableRow = this.dummyList;
            }
            console.log('this.showFilters',this.showFiltersForFindings)
			emitter.emit('visible', this.showFiltersForFindings);
		},
         filterOpenForAuditInfo() {
			this.showFiltersForAuditInfo = !this.showFiltersForAuditInfo;
            console.log('this.showFilters',this.showFiltersForAuditInfo)
			emitter.emit('visible', this.showFiltersForAuditInfo);
		},
        taskStatusColor(colorCode: any){
            if(colorCode == 10401){
                return ['w-8 h-4 bg-disabledgrey rounded-sm']
            } else if (colorCode == 10415) {
                return ['w-8 h-4 bg-yellow rounded-sm']
            } else if (colorCode == 10414) {
                return ['w-8 h-4 bg-electricGreen rounded-sm']
            } else if (colorCode == 10416) {
                return ['w-8 h-4 bg-primary rounded-sm']
            } else if (colorCode == 10403) {
                return ['w-8 h-4 bg-lightgreen rounded-sm']
            }else if (colorCode == 10417) {
                return ['w-8 h-4 bg-darkred rounded-sm']
            }
        },
        textColor(colorCode: any){
            if(colorCode == 10401){
                return ['font-semibold text-disabledgrey']
            } else if (colorCode == 10415) {
                return ['font-semibold text-yellow']
            } else if (colorCode == 10414) {
                return ['font-semibold text-electricGreen']
            } else if (colorCode == 10416) {
                return ['font-semibold text-primary']
            } else if (colorCode == 10403) {
                return ['font-semibold text-lightgreen']
            } else if (colorCode == 10417) {
                return ['font-semibold text-darkred']
            } else if (colorCode == 10418) {
                return ['font-semibold']
            }
        },
        getColumnHeadWidth(column: any) {
            if (column == 'tasks') {
                return 'w-28';
            } else {
                return '';
            }
        },
        changeDateFormat(date: any) {
            console.log("date",date)
			if (date.includes('-')) {
				let dateParts = date.split('-');
				if (dateParts.length === 6) {
				let startDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				let endDate = `${dateParts[5]}/${dateParts[4]}/${dateParts[3]}`;
				return `${startDate} - ${endDate}`;
				} else if (dateParts.length === 3) {
				return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				}
			}
			return date.split('-').reverse().join('/');
		},
         async toggleTabs(obj: any) {
            this.selectedTabIndex = obj.id;
            this.selectedTabObj = obj;
            this.showFiltersForFindings = false;
            this.showFiltersForAuditInfo = false;
		},
        async getAllTeams() {
            this.teamObject = []
            let users:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                users = res.data;
                this.teamsData = res.data;
            });
            for (var i = 0; i < users.length; i++) {
                this.teamObject.push({ label: users[i].teamName, value: users[i]._id });
            }
            console.log('teamObject',this.teamObject)
        },
        onclickTerminate(){
            this.showTerminatePopup = true
        },
        onCancelTerminate(){
            this.showTerminatePopup = false;
            this.terminateObj.terminateComments = '';
            this.terminateObj.retainFindings = false
        },
        async TerminatePresentAudit(){
            let payload:any = {
                "updatedAt": new Date(),
                "updatedBy": this.userInfo._id,
                "statusCode":10418,
                "comments":this.terminateObj.terminateComments,
                "retainFindings": this.terminateObj.retainFindings
            }
            await this.$http.post(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${this.$route.params.auditId}/terminate`,payload).then(async (res: any) => {
                console.log('TERMAINATED');
                this.$router.push({name:'audit'});
			});
        },
        // async closeAudit(){
        //     if(this.tasksOpen == true && this.presentAuditObject.statusCode == 10402){
        //         toast.error(`Tasks in Pending`, {
        //             timeout: 1000,
        //             closeOnClick: true,
        //             closeButton: 'button',
        //             icon: true
        //         });
        //     }else if(this.tasksOpen == true && this.presentAuditObject.statusCode == 10401){
        //         toast.error(`Audit Not Started Yet`, {
        //             timeout: 1000,
        //             closeOnClick: true,
        //             closeButton: 'button',
        //             icon: true
        //         });
        //     }else{
        //         console.log('YOU CAN CLOSE AUDIT')
        //     }
        // },
        async getPresentAuditObject() {
			await this.$http
				.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${this.$route.params.auditId}/get`)
				.then((result: { data: any }) => {
					this.presentAuditObject = result.data;
				})
				.catch((error: any) => {
					toast.error(` ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
                this.startDate = this.changeDateFormat(this.presentAuditObject.startDate)
                this.endDate = this.changeDateFormat(this.presentAuditObject.endDate)
                this.auditPeriod = `${this.changeDateFormat(this.presentAuditObject.period.fromDate)} - ${this.changeDateFormat(this.presentAuditObject.period.toDate)}`;

                this.presentAuditObject.location.forEach((location: any, index: number) => {
                // console.log("Before update:", location);
                let foundLocation = this.locationObject.find((obj: any) => obj.value == location);
                if (foundLocation) {
                    this.presentAuditObject.location[index] = foundLocation.label;
                } else{
                    this.presentAuditObject.location[index] =''
                }
                // console.log("After update:", this.presentAuditObject.location[index]);
            });

                this.presentAuditObject.entities.forEach((entity: any) => {
                    if (entity.teams && Array.isArray(entity.teams)) {
                        entity.teams.forEach((team: any) => {
                            let teamLeaderObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == team.auditee});
                            team.auditee = teamLeaderObj!=undefined ? teamLeaderObj.label : '';

                            let auditorObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == team.auditor});
                            team.auditor = auditorObj!=undefined ? auditorObj.label : '';

                            team.teamName1 = this.getTeamName(team.team);
                            team.entityName1 = this.getLocationName(team.entity)

                            this.teamsArray.push(team);
                        });
                        
                    }
                    console.log('teamsArray',this.teamsArray)
                    

                })
                     
                
                let teamLeaderObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == this.presentAuditObject.teamLeader});
                this.presentAuditObject.teamLeader = teamLeaderObj;
		},
        getTeamName(team: any) {
            let teamObj = this.teamsData.find((obj: any) => {
                return obj._id == team;
            });
            if (teamObj == undefined) {
                return '';
            } else {
                return teamObj.teamName;
            }
        },
        viewTasks(mainIndex:any){
            let findStatusCode = this.taskStatusCodes[mainIndex]
            if(this.presentAuditObject.statusCode != 10401){
                this.$router.push({ name: 'start-audit', params: { auditId: this.$route.params.auditId },query:{tasks:findStatusCode.id} });
            }else{
                toast.error(`Please Start Audit`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        startPresentAudit(){
            this.$router.push({ name: 'start-audit', params: { auditId: this.$route.params.auditId } });
        },
        clickOnPieLabel(event:any, chartContext:any, config:any){
            // console.log('eventevent', event)
            // console.log('chartContext',chartContext)
            // console.log('configconfig', config)
            // alert (chartContext.w.globals.labels[config.dataPointIndex] )
            this.viewTasks(config.dataPointIndex)
        },
         async getTasksCountData(aId:any) {
            this.series = []
			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/audit/${aId}/allstatus`).then(async (result: any) => {
				// this.viewAuditObj = result.data;

                let obj:any =[
                    {taskStatus:10401, tasks: result.data[0].tasks},
                    {taskStatus:10415, tasks: result.data[2].tasks},
                    {taskStatus:10414, tasks: result.data[1].tasks},
                    {taskStatus:10416, tasks: result.data[4].tasks},
                    {taskStatus:10403, tasks: result.data[5].tasks},
                    {taskStatus:10417, tasks: result.data[3].tasks},
                    {taskStatus:10418, tasks: result.data[6]!=undefined ? result.data[6].tasks:0},
                ]
                this.viewAuditObj = obj;
                this.viewAuditObj.map((obj:any)=>{this.series.push(obj.tasks)})

			});
		},
        async getallUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
				for (var i = 0; i < this.orgUsersList.length; i++) {
					if(this.orgUsersList[i].isActive == true){
						this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
					}
				}
			});
            
            
			console.log('this.orgUsersList', this.orgUsersList);
		},
         async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
            console.log("locationObject",this.locationObject)
           

            // this.presentAuditObject.location.forEach((location: any)=>{
            //             console.log("locationlocationlocationlocation",location)
            //             location = this.locationObject.find((obj:any)=>{
            //                 return obj.value == location
            //             })
            //             console.log("locationlocationlocationlocation",location)
            //             });
        //    this.presentAuditObject.location =  this.locationObject.label 
        //     console.log("locationObject",this.presentAuditObject.location)
        },
         async getAuditTasks(aId:any) {
			this.tasksForAudit = [];
			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${aId}/tasks/get`).then(async (result: any) => {
				this.tasksForAudit = result.data;
			});
             console.log('this.tasksForAudit',this.tasksForAudit)
		},
        getLocationName(locationId: any) {
            let user = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
            if (user == undefined) {
                let empty = '-';
                return empty;
            } else {
                return user.label;
            }
        },
         async getDetailsForTable(){

            this.tableRow = [];
            this.findingsForAudit.map((findingObj:any)=>{

                let presentLocation:any
            
                let presentteamObject:any = this.teamsData.find((obj: any) => {
                    return obj._id == findingObj.department;
                });

                if (presentteamObject.type == 20301){
                    presentLocation = 'Global';
                }else{
                    if(findingObj.location == undefined || findingObj.location==null){
                        presentLocation = '-'
                    }else{
                        presentLocation = this.getLocationName(findingObj.location[0].entity)
                    }
                }

                // taskObj.findings.map((obj:any)=>{
                    findingObj.entityName = presentLocation;
                    findingObj.teamName = presentteamObject.teamName;
                    findingObj.controlNumber = findingObj.frameWorkControl;
                    findingObj.findingStatus = findingObj.statusCode
                    this.tableRow.push(findingObj)
                // }) 
            })
            console.log('this.tableRow ',this.tableRow)
        },
         onCancelButtonAddPopUp() {
			this.showPopUp = false;
		},
        async getAuditFindings(aId:any){
            this.findingsForAudit = []
			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${aId}/findings/getall`).then(async (result: any) => {
				let auditFindings = result.data;
                this.findingsForAudit = auditFindings;
			})
		},
        findingsTabRowData(){
    // if(this.selectedTabIndex == 4){
        emitter.off('clickedOnDropDownAction')
		emitter.on('clickedOnDropDownAction',async (actionObj:any)=>{
            console.log('FRIDAY',actionObj)
            this.ActionObj.frameworks = actionObj.frameworks;
            this.ActionObj.findingCategory = actionObj.findingCategory;
            this.ActionObj.findingComment = actionObj.findingComment;
            this.ActionObj.findingEvidence = actionObj.findingEvidence;
            this.ActionObj.auditResult = actionObj.auditResult;
             this.ActionObj.findingId = actionObj.findingId;
            if(actionObj.frameWorkControl != undefined){
                this.ActionObj.frameWorkControl = actionObj.frameWorkControl
            }else{
                this.ActionObj.frameWorkControl = ''
            }
            this.showPopUp = true;
		})
    // }
       
    }
    },
    async created(){
        emitter.off('clickedOnDropDownAction')
        await this.getTasksCountData(this.$route.params.auditId);
        await this.getAllTeams();
        
        await this.getallUsers();
        
        await this.getallEntities();
        await this.getPresentAuditObject();
        
        this.presentAuditObject.auditMembers.forEach((member: any, index: number) => {
                // console.log("Before update:", location);
                let foundmember = this.optionsUserArray.find((obj: any) => obj.value == member);
                if (foundmember) {
                    this.presentAuditObject.auditMembers[index] = foundmember.label;
                } else{
                    this.presentAuditObject.location[index] =''
                }
                // console.log("After update:", this.presentAuditObject.location[index]);
            });
            
        await this.getAuditTasks(this.$route.params.auditId);

        await this.getAuditFindings(this.$route.params.auditId)

        await this.getDetailsForTable();
        
        let headersData = ['findingId','findingComment','frameworks','frameWorkControl','findingCategory','teamName','entityName','findingStatus']

        this.mainHeaders = headersData;
        if(this.tasksForAudit.length > 0){
            let result:any = this.tasksForAudit.every((obj:any)=>{return obj.statusCode==10403 || obj.statusCode == 10417});
            result == true ? this.tasksOpen = false : this.tasksOpen = true;
        }
        this.routesArray = [
            { name: 'Audit' , routeName: 'audit' },
            { name: this.presentAuditObject.title , routeName: 'viewAudit' },

        ];
        this.selectedTabObj = { displayName: 'Task Analytics', id: 1 };
		this.selectedTabIndex = 1;
        // this.navIndexNums = { index: 1, name: 'Task Analytics' };
        
    },
})
</script>
<template>
	<div>
		<div class="bg-white texttip absolute z-50 group-hover:opacity-100 border-lightgrey border pointer-events-none" :style="styling">
			<img src="@/assets/tooltip.svg" alt="" class="absolute text-black bottom-full left-0 -mb-1 h-3.5 w-3" />
			<span class="text-black text-center text-xs"></span>
			<div class="px-2">
				{{ tipText }}
			</div>
		</div>
	</div>
</template>
<style scoped>
.texttip{
    /* min-height: 20px; */
    min-width: 250px;
	/* max-width: 300px; */
	/* min-width: 140px !important; */
    /* background-color: blueviolet; */
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter } from '@/main';

export default defineComponent({
	props: ['columnName', 'tipText', 'styling'],
	data(): any {
		return {
			stylingObj: { left: '', bottom: '' },
			orgType: [
				{
					id: 1,
					value: 'Primary'
				},
				{
					id: 2,
					value: 'Subsidiary'
				},
				{
					id: 3,
					value: 'Branch'
				}
			],
			type: [
				{
					id: 1,
					value: 'Primary'
				},
				{
					id: 2,
					value: 'Secondary'
				}
			]
		};
	},
	methods: {
		colorRiskSeverity(tipText: any) {
			if (tipText == 1) {
				return ['font-semibold text-lightgreen'];
			} else if (tipText == 2) {
				return ['font-semibold text-green'];
			} else if (tipText == 3) {
				return ['font-semibold text-yellow'];
			} else if (tipText == 4) {
				return ['font-semibold text-error'];
			} else if (tipText == 5) {
				return ['font-semibold text-darkred'];
			}
		},
	}
});
</script>

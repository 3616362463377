<template>
	<div class="px-5 pt-3 pb-2 mb-5 ResponsiveAudit">
		<div class="items-center justify-between flex">
			<div class="title flex items-center">
				<h1>My Finding Tasks</h1>
			</div>
			<div class="flex items-center">
				
				<button class="flex" @click="filterOpen" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<div class="mt-3">
			<p class="headerText">
				An IT audit or information technology audit is an invertigation and evaluation of IT system,infrastructures,policies, and operations. Through IT audits, a company can determine if the existing IT controls protect corporate assets, ensure data integrity and align with the organization's business and financial controls.
			</p>
		</div>
		<div>
			<tableData :tableRowData="tableRow" :headersData="headersTable"></tableData>
		</div>
	</div>
</template>
<style scoped>
.btnPrimaryPadding{
	padding-left : 16px;
	padding-right : 16px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required } from '@vuelidate/validators';
import tableData from '@/components/tableData.vue';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';
import { emitter, toast } from '@/main';

export default defineComponent({
	data(): any {
		return { 
			v$: useVuelidate(),
			savedFindingSets: [],
			optionsUserArray: [],
			showFilters: false,
			dummyList: [],
			tableRow: [],
			optionUserArray:[],
            allTasks:[],
			teamObject:[],
			presentFindingTaskObj:{},
			users : [],
			headersTable:['taskId','action','department','location','assignee','statusCode'],
		};
	},
	components: {
		tableData
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
	},
	
	methods: {
		async getAllTeams() {
            this.teamObject = []
            // let users:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                this.users = res.data;
                this.teamsData = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.teamObject.push({ label: this.users[i].teamName, value: this.users[i]._id });
            }
        },
		getTeamName(team: any) {
			console.log("team",team)
			console.log("dsjgjh")
            let teamObj = this.users.find((obj: any) => {
                return obj._id == team;
            });
            if (teamObj == undefined) {
                return '';
            } else {
				console.log("djkshkjsh")
                return teamObj.teamName;
            }
        },
		 async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
            console.log("locationObject",this.locationObject)
        },
		getLocationName(locationId: any) {
			console.log("locationId",locationId)
            let locationObj = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
			console.log("locationObj",locationObj)
            if (locationObj == undefined) {
                let empty = '';
                return empty;
            } else {
				console.log("jgfdgfhg")
                return locationObj.label;
            }
        },
		async getAllFindingTasks(){
			this.tableRow = []
            await this.$http
				.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/tasks/getall`)
				.then((result: any) => {
                console.log("ResultResultResult",result.data)
                this.allTasks = result.data;

				   
                   this.allTasks.forEach((task:any) => {
						if (task.actionType === 2) {
						
							if(task.department == undefined || task.department == null){
								task.department = '-'
							} else {
								task.department = this.getTeamName(task.department)
							}


							let locationName:any  = this.getLocationName(task.location)


							this.tableRow.push({ ...task,location: locationName ,department: task.department});
							
						}
						
					})
                   
            });
            
        },
		
        goToFindingsInfo(obj:any){
            this.$router.push({ name: 'findingTaskEdit', params: { findingId: obj.linkedFindingId , taskId: obj._id} });
        },
		filterOpen() {
			this.showFilters = !this.showFilters;
			if (this.showFilters == true) {
                this.dummyList = this.tableRow;
            } else {
                this.tableRow = this.dummyList;
            }
			emitter.emit('visible', this.showFilters);
		},
        
	},
	async created() {
		await this.getAllTeams();
        await this.getallEntities();
        await this.getAllFindingTasks();
        emitter.off('clickedOnDropDownAction')
		emitter.on('clickedOnDropDownAction',async (actionObj:any)=>{
            console.log('áskhdsakudbs',actionObj)
            this.goToFindingsInfo(actionObj)
		})
		
    }  
});
</script>
 
<template>
	<div class="px-5 pt-3 pb-2 mb-5 ResponsiveAudit">
		<div class="items-center justify-between flex">
			<div class="title flex items-center">
				<h1>Audit</h1>
			</div>
			<div class="flex items-center">
				<button class="btnprimary ml-3 flex items-center btnPrimaryPadding" @click="createNewAudit">Add</button>
				<button class="ml-3 flex" @click="filterOpen" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<div class="mt-3">
			<p class="headerText">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>
		<div>
			<tableData :tableRowData="tableRow" :headersData="headersTable"></tableData>
		</div>
	</div>
</template>
<style scoped>
.btnPrimaryPadding{
	padding-left : 16px;
	padding-right : 16px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required } from '@vuelidate/validators';
import tableData from '@/components/tableData.vue';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';
import { emitter, toast } from '@/main';

export default defineComponent({
	data(): any {
		return { 
			v$: useVuelidate(),
			savedAuditSets: [],
			optionsUserArray: [],
			showFilters: false,
			selected: '',
			autoSuggestion: false,
			dummyList: [],
			dummyAuditObj: [],
			tableRow: [],
			optionUserArray:[],
			headersTable:['auditId','title','auditPeriod','startDate','endDate','auditor1','auditStatus','actionButtonAtAudits'],
		};
	},
	components: {
		tableData
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		e: function (): any {
			return false;
		}
	},
	async mounted() {
		await this.getAllUsers()
		await this.getAuditSets();

		emitter.off('clickedOnDropDownAction')
		emitter.on('clickedOnDropDownAction',async (actionObj:any)=>{
			await this.changeRouteForAction(actionObj.action,actionObj.data)
		})
	},
	methods: {
		async changeRouteForAction(action:any,rowData:any){
			if(action=='Edit'){
				if(rowData.createdBy == this.userInfo._id){
					this.$router.push({ name: 'edit-audit', params: { auditId: rowData._id } });
				}else{
					toast.error('Cannot Edit', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}			
			}else if(action=='Controls'){
				this.$router.push({ name: 'audit-controls', params: { auditId: rowData._id } });
			} else if(action == 'Start Audit'){
				// await this.startSelectedAudit(rowData._id)
				this.$router.push({ name: 'start-audit', params: { auditId: rowData._id } });
			} else if(action == 'View'){
				// await this.startSelectedAudit(rowData._id)
				this.$router.push({ name: 'view-audit', params: { auditId: rowData._id } });
			} 
		},
		filterOpen() {
			this.showFilters = !this.showFilters;
			if (this.showFilters == true) {
                this.dummyList = this.savedAuditSets;
            } else {
                this.savedAuditSets = this.dummyList;
            }
			emitter.emit('visible', this.showFilters);
		},
		createNewAudit() {
            this.$router.push({name:'add-audit'});
		},
		async getAllUsers() {
			this.optionUserArray = []
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				for (var i = 0; i < res.data.length; i++) {
					this.optionUserArray.push({ label: res.data[i].name, value: res.data[i]._id });
				}
			});
		},
		changeDateFormat(date: any) {
			if (date.includes('-')) {
				let dateParts = date.split('-');
				if (dateParts.length === 6) {
				let startDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				let endDate = `${dateParts[5]}/${dateParts[4]}/${dateParts[3]}`;
				return `${startDate} - ${endDate}`;
				} else if (dateParts.length === 3) {
				return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				}
			}
			return date.split('-').reverse().join('/');
		},
		
		async getAuditSets() {
			this.savedAuditSets = [];

			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audits/getAll`).then((result: any) => {
				result.data.forEach((info: any) => {
					this.savedAuditSets.push(info);
					this.dummyList.push(info);
				});
				console.log('savedAuditSets', this.savedAuditSets);
			})
			
			this.savedAuditSets.forEach((auditObj: any) => {
				// console.log('optionUserArray',this.optionUserArray)
				let findName = this.optionUserArray.find((obj:any)=>{return obj.value == auditObj.teamLeader});
				let auditPeriodFormat = `${this.changeDateFormat(auditObj.period.fromDate)} - ${this.changeDateFormat(auditObj.period.toDate)}`
				this.tableRow.push({
					_id: auditObj._id,
					auditId: auditObj.auditId,
					title: auditObj.title,
					startDate: auditObj.startDate,
					endDate : auditObj.endDate,
					auditPeriod: auditPeriodFormat,
					auditor1: findName!=undefined? findName.label:'',
					auditStatus: auditObj.statusCode,
					createdBy: auditObj.createdBy
				});
				console.log('findName.label',findName)
			});
			console.log('tableRow',this.tableRow)
		}
	},
	async created() {
        // await this.getAuditSets();
		window.addEventListener('click', (e) => {
			this.autoSuggestion = false;
		});
	}
});
</script>
 